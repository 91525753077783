.self {
  height: 100vh;
  position: relative;
}
.title {
  width: 100vw;
  color: darkgray;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}